import React from "react"
import { graphql, Link } from "gatsby"
import Seo from "src/utils/Seo"
import Breadcrumbs from "src/utils/Breadcrumbs"
import Pricing from "src/components/Product/Pricing"
import Fade from "react-reveal/Fade"
import ProductAccordion from "src/components/Product/ProductAccordion"

const ArchiveFrontDoorPricing = ({ data: { allPrismicFrontDoor } }) => {
  const data = allPrismicFrontDoor

  const crumbs = [
    { label: "Front Doors", href: "/front-doors" },
    { label: "Front Door Pricing", href: "/front-doors/prices", active: true },
  ]

  return (
    <main>
      <Seo title={"Front Door Pricing"} 
        description={
          "See here for approximate guide prices for our bespoke, contemporary front doors. Call us today or request an online quotation."
        }
      />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <Pricing {...data} type={"front door"} />
      <ProductAccordion type="front-doors" />
      <section className={`bg-offwhite`}>
        <div className={`container`}>
          <div className={`flex flex-col lg:flex-row justify-between`}>
            <div className="lg:w-1/2 lg:order-none order-first">
              <Fade distance="20px" bottom>
                <div>
                  <video className="w-full" autoPlay loop muted playsInline>
                    <source
                      src={`https://d2jpqhjplyyyp0.cloudfront.net/Deuren-Video-Front-1000-800-High-Res.mp4`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </Fade>
            </div>
            <div className="lg:w-40p lg:text-left text-center">
              <Fade distance="20px" bottom>
                <div>
                  <h2 className="font-display text-gold rfs:text-6xl lg:mt-0 mt-8">
                    Discounts available
                  </h2>
                  <div className="w-12 mt-5 separator"></div>
                  <div className="mt-5">
                    <p>
                      Front door prices vary depending on the choice of design,
                      finish, size and whether they’re a FD30 or FD60
                      (fire-rated doors).{" "}
                    </p>
                    <p>
                      Discounts are available for an increase in quantity of
                      doors. Contact the team to find out more about discounts
                      on our front doors.
                    </p>
                  </div>
                  <Link className="btn mt-6" to={`/contact/`} role="button">
                    Contact the team
                  </Link>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-offwhite">
        <div className="container text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold max-w-lg m-auto rfs:text-6xl">
              Internal and Garage Door Pricing
            </h2>
            <div className="w-10 separator mt-4"></div>
            <p className="max-w-xl m-auto mt-6">
              See our pricing for internal and garage doors, where you can
              seamlessly match the door style or woods and finishes across
              different door styles.
            </p>
          </Fade>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-12 text-left">
            <Fade distance="20px" bottom>
              <Link
                to={`/garage-doors/prices/`}
                className="group"
                title={`Garage Door Pricing`}
              >
                <img
                  src="https://images.prismic.io/deuren/cefb6ac9-ece5-42f4-96c1-1c17fc300f22_Deuren-Garage-Door-Pricing-Discounts-available.jpg?auto=compress,format"
                  alt="Garage Door Pricing"
                />
                <p
                  className={`font-bold text-xs tracking-widest uppercase mt-4 group-hover:text-gold duration-500`}
                >
                  GARAGE DOOR PRICING
                </p>
              </Link>
              <Link
                to={`/internal-doors/prices/`}
                className="group"
                title={`Internal Door Pricing`}
              >
                <img
                  src="https://images.prismic.io/deuren/f12a0682-ddb3-44b2-9eb8-d868806286e5_Deuren-Internal-Door-Pricing-Discounts-available.jpg?auto=compress,format"
                  alt="Deuren Internal Door Pricing Discounts available"
                />
                <p
                  className={`font-bold text-xs tracking-widest uppercase mt-4 group-hover:text-gold duration-500`}
                >
                  Internal DOOR PRICING
                </p>
              </Link>
            </Fade>
          </div>
        </div>
      </section>
      <section className="bg-verylightgrey">
        <div className="container text-center">
          <Fade distance="20px" bottom>
            <h2 className="font-display text-gold rfs:text-6xl">
              Download the brochure
            </h2>
            <div className="w-12 mt-5 separator"></div>
            <p className="max-w-2xl my-6 m-auto">
              Find out more about our front, internal and garage door ranges.
              Featuring our most popular designs along with technical
              specifications, configuration information, our range of woods and
              finishes, and available accessories, you can pick your perfect new
              door from the comfort of your own home.
            </p>
            <Link className="btn" to={`/brochure-downloads/`} role="button">
              Download the brochure
            </Link>
          </Fade>
        </div>
      </section>
    </main>
  )
}

export default ArchiveFrontDoorPricing

export const frontDoorPricingQuery = graphql`
  query FrontDoorPricing {
    allPrismicFrontDoor {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            pricing {
              price
              title {
                text
              }
            }
            pricing_image {
              url
              alt
            }
          }
        }
      }
    }
  }
`
